.App-body {
  padding: 4rem 2rem;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* font-size: calc(2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

.email {
  justify-content: left;
}

a {
  color: #ff9500;
}
