.c-navBar {
  min-width: 100%;
  height: 7rem;
  background-color: black;
  color: #ff9500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 8%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 10;
}

.c-navBar a {
  color: #ff9500;
}
